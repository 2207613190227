import React from "react";

export default ({ fill = "#fff", opacity = "0.25" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.899"
    height="14.9"
    viewBox="0 0 14.899 14.9"
  >
    <g id="info" fill={fill} fillOpacity={opacity}>
      <circle cx="7.438" cy="4.57" r="0.863" />
      <polygon points="8.025,6.957 8.025,6.723 8.025,6.511 6.297,6.511 6.297,6.957 6.872,6.957 6.872,10.748 6.297,10.748 6.297,11.195 8.603,11.195 8.603,10.748 8.025,10.748" />
      <path d="M7.45,14.9C3.342,14.9,0,11.559,0,7.451C0,3.343,3.342,0,7.45,0c4.107,0,7.449,3.343,7.449,7.451 C14.899,11.559,11.558,14.9,7.45,14.9z M7.45,0.5C3.618,0.5,0.5,3.618,0.5,7.451c0,3.832,3.118,6.949,6.95,6.949 s6.949-3.117,6.949-6.949C14.399,3.618,11.282,0.5,7.45,0.5z" />
    </g>
  </svg>
);
